.m-newsletter-us {
  margin-bottom: 30px !important;
  &__inner {
    border-radius: 12px;
    background: $white;
    box-shadow: $fluffy-pancake-alt;
    margin-left: 10px;
  }

  &.m-newsletter--signed-up {
    display: none;
  }

  &-heading {
    padding-top: 16px;
    display: flex;

    .m-newsletter-us {
      &__logo {
        position: relative;

        .highlight {
          width: 64px;
          height: 68px;
          flex-shrink: 0;
          background: $submarine-yellow;
        }

        .aolToday-logo {
          position: absolute;
          top: 10px;
          left: 28px;

          .icon_aolToday {
            width: 100px;
            height: 50px;
          }
        }
      }

      &__title {
        display: flex;
        span {
          padding-top: 16px;
          padding-left: 74px;
          padding-right: 63px;
          font-family: $font-family-poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.72px;
        }

        .xs-plus {
          display: none;
        }
      }
    }
  }

  &__desc {
    margin: 24px 37px 20px;
    color: $black;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.42px;
    text-align: left;

    &.xs-plus {
      display: none;
    }
  }

  &__form {
    &.xs-plus {
      display: none;
    }
    &--email {
      width: 100%;
      // margin: 0 0 16px;
      border-radius: 60px;
      height: 44px;
      border: 1px solid $koala;
      background: $white;
      padding-left: 28px;

      &:focus {
        outline: none;
      }
    }

    &--submit {
      // display: inline-flex;
      height: 44px;
      padding: 14px 24px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 48px;
      border: 1px solid $gotham;
      background: $white;
      color: $gotham;
      font-family: $font-family-poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 123.077% */
      margin-right: 20px;
      margin-top: 28px;
      margin-bottom: 20px;
      cursor: pointer;

      &.valid {
        background: $peach_yellow;
        border: 1px solid $peach_yellow;
      }
    }

    .sign-up-container {
      display: flex;
      justify-content: flex-end;
    }

    .email-container {
      margin: 0 20px;
    }

    .invalid-message {
      margin-left: 20px;
      margin-top: 8px;
      color: $tomato;
      font-family: $font-family-sans-serif;
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.2px;
      

      &.hidden {
        display: none;
      }
    }

    &.m-newsletter--error {
      input[name="newsletter-email"] {
        border: 1px solid $tomato;
        background: $salsa;
      }

      input[type='submit'] {
        margin-top: 0;
        border: 1px solid $koala;
        background: $white;
        color: $koala;
      }
    }
  }

  &__success {
    flex-direction: column;
    align-items: center;
    padding: 0 54px;
    display: none;

    &-message {
      color: $gotham;
      text-align: center;
      font-family: $font-family-poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.96px;
      margin-top: 12px;
      margin-bottom: 36px;

      &-sub {
        color: $gotham;
        text-align: center;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.48px;
        margin-bottom: 56px;
      }
    }
  }

  &__cross-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    display: none;
  }

  &.m-newsletter--success {
    // success state

    .m-newsletter-us {
      &__inner {
        position: relative;
      }

      &-heading {
        .left {
          display: none;
        }
      }

      &__success {
        display: flex;
      }

      &__cross-icon {
        display: block;
      }
    }

    .xxs-only {
      display: none;
    }
  }

  &--hide {
    display: none;
  }

  &.rr {
    // right-rail
    
    width: 100%;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .m-newsletter-us {
    &__inner {
      .xxs-only {
        display: none;
      }
    }

    &-heading {
      .m-newsletter-us {
        &__title {
          flex-direction: column;
          span {
            font-size: 16px;
            text-align: start;
            padding-left: 70px;
          }
        }

        &__desc {
          margin: 16px 72px 20px 70px;

          &.xs-plus {
            display: block;
          }
        }

        &__form {
          display: flex;
          margin-left: 50px;

          .email-container {
            min-width: 265px;
            height: 75px;
          }

          .invalid-message { 
            margin-top: 4px;
          }

          &--submit {
            margin-top: 0;
          }
        }
      }
    }

    &.m-newsletter--success {
      // success state
      .m-newsletter-us {
        &__inner {
          display: flex;
        }
        &__form {
          display: none;
        }

        &-heading {
          padding-bottom: 16px;
        }

        &__success {
          align-items: flex-start;
          justify-content: center;
          gap: 10px;
          padding-left: 70px;
          &-message {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.48px;
            margin: 0;

            &-sub {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.36px;
              text-align: left;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-newsletter-us {
    &__inner {
      padding: 8px 0;
    }
    &-heading {
      padding-top: 0;
      .m-newsletter-us {
        &__logo {
          .highlight {
            width: 80px;
            height: 72px;
          }

          .aolToday-logo {
            left: 40px;

            .icon_aolToday {
              width: 108px;
              height: 54px;
            }
          }
        }
      }
      .flex-box {
        display: flex;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
        }

        .m-newsletter-us {
          &__title {
            span {
              padding: 0 0 0 65px;
            }
          }

          &__desc {
            margin: 0 0 0 65px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.36px;
          }

          &__form {
            margin-left: 0;

            &--email,
            &--submit {
              margin-top: 14px;
              margin-bottom: 0;
            }

            &--email {
              padding-left: 20px;
            }

            .email-container {
              min-width: 190px;
            }

            &.m-newsletter--error {
              input[type='submit'] {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }

    &.m-newsletter--success {
      // success state
      .m-newsletter-us {
        &-heading {
          padding-bottom: 0;
        }

        &__success {
          flex-direction: row;
          align-items: center;
          gap: 24px;

          &-message {
            font-size: 26px;
            letter-spacing: -0.78px;
            text-align: start;
            min-width: 279px;

            &-sub {
              line-height: 14px;
              padding-right: 54px;
            }
          }
        }
      }
    }
    &__cross-icon {
      position: absolute;
      right: 12px;
      top: 12px;
    }

    &.rr {
      // right rail
  
      .m-newsletter-us {
        &__inner {
          margin-left: 0;
          .xxs-only {
            display: block;
          }
        }
  
        &__logo {
          .highlight {
            width: 64px;
            height: 68px;
          }
  
          .aolToday-logo {
            left: 28px;
  
            .icon_aolToday {
              width: 100px;
              height: 50px;
            }
          }
        }
  
        &-heading {
          flex-direction: column;
          .flex-box {
            .left {
              .xs-plus {
                display: none;
              }
              .m-newsletter-us__title {
                margin-top: 30px;
                span {
                  padding-left: 28px;
                  padding-right: 99px;
                  font-size: 24px;
                  line-height: 20px; 
                  letter-spacing: -0.72px;
                }
              }
            }
            .right {
              display: none;
            }
          }
        }
      }

      &.m-newsletter--success {
        // success
        .m-newsletter-us {
          &__inner {
            display: block;
            
            .xxs-only {
              display: none;
            }
          }
          
          &__success {
            gap: 20px;
            flex-direction: column;
            align-items: center;
            padding: 0 54px;
            
            &-message {
              min-width: initial;
              font-size: 32px;
              line-height: 32px;
              letter-spacing: -0.96px;
              margin-top: 12px;
              
        
              &-sub {
                min-width: initial;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.48px;
                margin-bottom: 56px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  .m-newsletter-us {
    &.m-newsletter {
      margin-bottom: 30px !important;
    }
    &-heading {
      .flex-box {
        .left {
          flex-direction: row;
          align-items: center;
        }
        .m-newsletter-us {
          &__title {
            min-width: 251px;
            span {
              font-size: 26px;
              font-weight: 700;
              line-height: 24px;
            }
          }

          &__desc {
            margin-left: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
            letter-spacing: -0.36px;
            min-width: 185px;
          }

          &__form {
            .email-container {
              min-width: 260px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)) {
  .m-newsletter-us {
    &-heading {
      .flex-box {
        .m-newsletter-us {
          &__form {
            .email-container {
              min-width: 230px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .m-newsletter-us {
    &-heading {
      .flex-box {
        .m-newsletter-us {
          &__form {
            .email-container {
              min-width: 330px;
            }
          }
        }
      }
    }

    &.m-newsletter--success {
      // success state

      .m-newsletter-us {
        &__success {
          padding-left: 114px;
          gap: 40px;

          &-message {
            font-size: 32px;
            letter-spacing: -0.96px;
            min-width: 345px;

            &-sub {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.48px;
              padding-right: 170px;
              min-width: 321px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .m-newsletter-us {
    &-heading {
      .m-newsletter-us {
        &__logo {
          .highlight {
            width: 196px;
          }
          .aolToday-logo {
            left: 156px;
          }
        }
      }
      .flex-box {
        .m-newsletter-us {
          &__form {
            .email-container {
              min-width: 330px;
            }
          }
        }
      }
    }

    &.m-newsletter--success {
      // success state

      .m-newsletter-us {
        &__success {
          gap: 50px;

          &-message {
            &-sub {
              padding-right: 245px;
              min-width: 321px;
            }
          }
        }
      }
    }
  }
}
