@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';
@import './newsletter_signup_v2';
@import './newsletter_signup_us';

.m-newsletter-signup {
  position: relative;
  width: 100%;
  border: 1px solid #e4e4e4;
  overflow: hidden;

  &--bg {
    position: absolute;
    top: 0%;
    left: 0%;
    fill: $brand-news;
    opacity: 0.1;
    pointer-events: none;

    .right-rail & {
      top: -50%;
    }
  }
}

.m-newsletter-minimal {
  max-width: 630px;
  width: 100%;
  margin: auto;
  padding: 20px;
  font-family: var(--font-family-base);

  .right-rail & {
    padding: 20px 15px;
  }

  &--icon {
    width: 100%;

    svg {
      fill: $brand-news;

      .right-rail & {
        width: 75px;
      }
    }
  }

  &--heading {
    font-size: 24px;
    color: $brand-news;
    margin: 10px auto;

    .right-rail & {
      margin: 5px auto;
      font-size: 18px;
    }
  }

  &--details {
    width: 70%;
    font-size: 16px;
    margin: auto;
    color: $grey-medium;
    display: block;

    p {
      margin: 0;
    }

    .right-rail & {
      width: 100%;
      font-size: 14px;
    }
  }

  &--form {
    position: relative;
    height: 45px;
    margin-top: 20px;

    .right-rail & {
      height: 25px;
      margin-top: 20px;
    }

    &__icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      fill: $brand-news;

      .right-rail & {
        svg {
          display: none;
        }
      }
    }

    &__email {
      border: 1px solid $brand-news;
      box-sizing: border-box;
      height: 45px;
      padding: 12px 12px 12px 55px;
      font-size: 16px;
      width: 70%;
      float: left;

      .right-rail & {
        border-width: 1px;
        font-size: 14px;
        padding-left: 8px;
        height: 35px;
      }
    }

    &__submit {
      height: 45px;
      background-color: $brand-news;
      border: 0px;
      color: $white;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 2px;
      padding: 10px;
      position: relative;
      text-align: center;
      width: calc(30% - 5px);
      float: right;

      .right-rail & {
        font-size: 14px;
        height: 35px;
      }
    }
  }

  &--social {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .right-rail & {
      margin-top: 60px;
      padding: 0 35px;
    }

    &__icons {
      display: flex;
      justify-content: center;

      .share-button {
        align-items: center;
        background-color: #3354a4;
        border-radius: 3px;
        color: white;
        display: flex;
        font-size: 14px;
        justify-content: center;
        min-height: 30px;
        text-decoration: none;
        width: 150px;

        .right-rail & {
          width: 90px;
        }

        &.twitter-share {
          background-color: #1b95e0;
          margin-left: 20px;
        }

        svg {
          fill: white;
          margin-left: 5px;
          width: 20px;
        }

        span {
          display: inline-block;
          padding: 7px;
        }
      }
    }
  }
}

.m-newsletter-banner {
  margin-top: 70px;
  border: none;
  min-height: 65vh;

  &--icon {
    margin: 50px 0;

    svg {
      fill: $brand-news;
    }
  }

  &--details {
    width: 70%;
    font-size: 16px;
    margin: auto;
    color: $grey-medium;
    display: block;

    p {
      font-size: 17px;
    }
  }

  &--heading {
    font-size: 24px;
    color: $brand-news;
    margin: 10px auto;
  }

  .signup-email {
    border: 1px solid #c9c9c9;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-sizing: border-box;
    height: 50px;
    line-height: 40px;
    padding: 12px;
    text-align: left;
    vertical-align: top;
    font-size: 17px;
    margin: 0;
    width: 60%;
  }

  .aol-btn.secondary-btn.send-sail-throu-signup {
    background-color: $blue;
    border: 1px solid $blue;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: $white;
    cursor: pointer;
    letter-spacing: 2px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    height: 50px;
    margin: 0;
    width: 40%;
  }

  img {
    max-width: 80%;
  }

  @media (min-width: 576px) {
    .signup-email {
      width: 50%;
      height: 55px;
    }

    .aol-btn.secondary-btn.send-sail-throu-signup {
      width: 30%;
      height: 55px;
    }

    img {
      max-width: 50%;
    }
  }

  .social {
    display: flex;
    flex-direction: column;
    margin-top: 35px;

    .right-rail & {
      margin-top: 60px;
      padding: 0 35px;
    }

    .icons {
      display: flex;
      justify-content: center;

      .share-button {
        margin-top: 25px;
        align-items: center;
        background-color: #3354a4;
        border-radius: 5px;
        color: white;
        display: flex;
        font-size: 14px;
        justify-content: center;
        min-height: 35px;
        text-decoration: none;
        width: 150px;

        &.twitter-share {
          background-color: #1b95e0;
          margin-left: 20px;
        }

        svg {
          fill: white;
          margin-left: 5px;
          width: 20px;
        }

        span {
          display: inline-block;
          padding: 7px;
        }
      }
    }
  }

  .newsletter-message {
    padding-top: 0;
  }
}

.newsletter-message {
  font-size: 17px;

  .error {
    color: $brand-danger;
    font-weight: 700;
  }

  .success {
    color: $brand-success;
    font-weight: 700;
  }
}

.newsletter-notice {
  display: none;
  padding-top: 10px;

  p {
    font-size: 16px;
  }
}
